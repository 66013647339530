.businessSegmentsPage {
  height: calc(100vh);
}

.businessSegmentsPage > div {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: repeat(2, calc((100vh - 56px) / 2));
}

.selected {
  @apply font-bold opacity-100 text-secondary-light;
}
.unselected {
  @apply opacity-70;
}
.selected:first-of-type,
.unselected:first-of-type {
  border-left: none;
}

@media screen and (max-width: 700px) {
  .businessSegmentsPage > div {
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: auto;
  }
  .businessSegmentsPage {
    height: auto;
  }
  .heading {
    order: -1;
  }
}
@media screen and (max-width: 500px) {
  .businessSegmentsPage > div {
    grid-template-columns: 1fr;
  }
  .navLi {
    writing-mode: vertical-rl;
    text-orientation: upright;
    vertical-align: top;
  }
}
