.aboutUsPageContainer {
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: auto auto;
  gap: 48px;
}
.grid:before {
  content: "";
  display: block;
}
.imgContainer {
  grid-column: 2/-1;
}
@media screen and (max-width: 500px) {
  .imgContainer,
  .item {
    grid-column: 1/-1;
  }
}
