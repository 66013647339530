.headerContainer ul li {
  @apply border-l-0.5 border-main border-opacity-40 px-8 py-4 hover:font-bold;
}

.headerContainer ul li:first-of-type {
  @apply border-none md:border-l-0.5;
}

.selected {
  @apply font-bold text-secondary-light;
}
