.footerContainer ul li > p {
  @apply text-secondary-light font-bold mb-6;
}

.qrContainer {
  @apply flex flex-col items-center;
}

.qrContainer img {
  @apply w-16;
}
.qrContainer p {
  @apply text-xs;
}
